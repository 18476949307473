import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

const ICON_ID = 'points_of_interest_leisure';
const namespace = 'ui-pdp-icon ui-pdp-icon--poi-leisure';

const IconLeisure = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconLeisure.propTypes = {
  className: string,
};

IconLeisure.defaultProps = {
  className: null,
};

IconLeisure.ICON_ID = ICON_ID;

export default React.memo(IconLeisure);
export { IconLeisure };
